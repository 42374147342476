import React from "react";
import Layout from "../components/Templates/Layout";
import Meta from "../utils/Meta";
import Link from "../components/Global/Link";

// Markup
const IndexPage = () => {
  const openGraph = {
    type: "website",
    title: "CP 4 RP",
    socialTitle: "All the details for Claire & Richy's big day",
    description: "20th May 2023 | Left Bank, Leeds",
  };

  return (
    <Layout>
      <Meta openGraph={openGraph} />
      <section
        className="d-flex flex-column vw-100 vh-100 justify-content-center align-items-center py-5 brand-background-cover"
        id="home"
      >
        <div className="container-fluid text-center">
          <div className="row justify-content-center gy-4">
            <div className="col-12">
              <h1 className="brand-font brand-responsive">Richy & Claire</h1>
              <h2 className="brand-font brand-responsive">
                Saturday 20th May 2023
              </h2>
              <h3 className="brand-font brand-responsive mb-4">
                Left Bank, Leeds
              </h3>
              <h3 className="brand-font brand-responsive mb-5">2pm Arrival</h3>
            </div>
          </div>
          <div className="row justify-content-center gy-4">
            <div className="col-12 col-md-auto">
              <Link
                to="https://calendar.google.com/event?action=TEMPLATE&tmeid=MjNiMWRsdTVjZmgzYTNsc211bDI1M2tvYTQgcmljaHlwZWFyc0Bt&tmsrc=richypears%40gmail.com"
                icon="calendar-plus"
                button
              >
                Add
              </Link>
            </div>
            <div className="col-12 col-md-auto">
              <Link to="/rsvp/" button icon colour="pink">
                Confirm RSVP
              </Link>
            </div>
            <div className="col-12 col-md-auto">
              <Link
                to="https://www.google.com/maps/d/u/0/edit?mid=1vnYGjzsNPGm-0MXWlKAXDqIaJtltQ2E&usp=sharing"
                button
                icon="globe"
              >
                Map
              </Link>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default IndexPage;
